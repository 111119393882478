<template>
    <ul class="profle_details">
        <li>
            <div class="info_card">
                <h4>Full Name</h4>
                <p v-if="!infoEdit">Michele Rosiello</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="Michele Rosiello">
                </div>
            </div>
            <div class="info_card">
                <h4>E-mail</h4>
                <p v-if="!infoEdit">Rosiello@onboardme.io</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="Rosiello@onboardme.io">
                </div>
            </div>
            <div class="info_card">
                <h4>Mobile Number</h4>
                <p v-if="!infoEdit">9883123456</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" v-model="phone" >
                </div>
            </div>            
        </li>
        <li>
            <div class="info_card">
                <h4>Status</h4>
                <p v-if="!infoEdit">New Leads</p>
                <div v-if="infoEdit" class="field_wpr">
                    <Multiselect v-model="status.value" v-bind="status"></Multiselect>
                </div>
            </div>
            <div class="info_card">
                <h4>Temperature</h4>
                <div v-if="!infoEdit" class="status_bar"><span class="warm"></span></div>
                <div v-if="infoEdit" class="field_wpr">
                    <Multiselect v-model="temperature.value" v-bind="temperature"></Multiselect>
                </div>
            </div>
            <div class="info_card">
                <h4>Last Action Taken</h4>
                <p v-if="!infoEdit">7:30 PM</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="7:30 PM">
                </div>
            </div>            
        </li>
        <li>
            <div class="info_card">
                <h4>Business Name</h4>
                <p v-if="!infoEdit">Rosiello-son.co</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="Rosiello-son.co">
                </div>
            </div>
            <div class="info_card">
                <h4>Business Number</h4>
                <p v-if="!infoEdit">+1 539-225-999</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" v-model="phone" >
                </div>
            </div>
        </li>
        <li>
            <div class="info_card">
                <h4>Address</h4>
                <p v-if="!infoEdit">163rd Street Washington, DC 20</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="163rd Street Washington, DC 20">
                </div>
            </div>
            <div class="info_card">
                <h4>City</h4>
                <p v-if="!infoEdit">Washington D.C</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="Washington D.C">
                </div>
            </div>
            <div class="info_card">
                <h4>State</h4>
                <p v-if="!infoEdit">Washington</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="Washington">
                </div>
            </div>            
        </li>
        <li>
            <div class="info_card">
                <h4>Zip Code</h4>
                <p v-if="!infoEdit">56877</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="56877">
                </div>
            </div>
            <div class="info_card">
                <h4>Country</h4>
                <p v-if="!infoEdit">USA</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="USA">
                </div>
            </div>
            <div class="info_card">
                <h4>Time zone</h4>
                <p v-if="!infoEdit">3:31 AM</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="3:31 AM">
                </div>
            </div>            
        </li>
        <li>
            <div class="info_card">
                <h4>Gender</h4>
                <p v-if="!infoEdit">Male</p>
                <div v-if="infoEdit" class="field_wpr">
                    <Multiselect v-model="gender.value" v-bind="gender"></Multiselect>
                </div>
            </div>
            <div class="info_card">
                <h4>Source</h4>
                <p v-if="!infoEdit">USA</p>
                <div v-if="infoEdit" class="field_wpr">
                    <input type="text" placeholder="USA">
                </div>
            </div>
            <div class="info_card">
                <h4>Date of Birth</h4>
                <p v-if="!infoEdit">---</p>
                <div v-if="infoEdit" class="field_wpr">
                    <datepicker v-model="date" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                </div>
            </div>            
        </li>
    </ul>
    <div class="action_wpr">
        <button class="btn cancel_btn">Delete</button>
        <button v-if="!infoEdit" class="btn save_btn" @click="infoEdit = true">Edit</button>
        <button v-if="infoEdit" class="btn save_btn">Save</button>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default{
    name: 'ProfileDetails',
    components:{
        Multiselect
    },
    data(){
        return{
            infoEdit: false,
            date: '',
            status: {
                mode: 'single',
                value: ['New Leads'],
                options: [
                    { value: 'New Leads', label: 'New Leads' },
                    { value: 'Made Contact', label: 'Made Contact' },
                    { value: 'Appt Booked', label: 'Appt Booked' },
                    { value: 'Appt Show', label: 'Appt Show' },
                    { value: 'Appt No Show', label: 'Appt No Show' },
                    { value: 'Active Trial', label: 'Active Trial' },
                    { value: 'Inactve Trial', label: 'Inactve Trial' },
                    { value: 'Active Clients', label: 'Active Clients' },
                    { value: 'Inactive Clients', label: 'Inactive Clients' },
                    { value: 'Active Staff', label: 'Active Staff' },
                    { value: 'Active Staff', label: 'Active Staff' },
                    { value: 'Inactive Staff', label: 'Inactive Staff' },
                    { value: 'Vendors', label: 'Vendors' },
                    { value: 'Lost', label: 'Lost' }
                ],
                searchable: true
            },
            temperature:{
                mode: 'single',
                value: ['Warm'],
                options: [
                    { value: 'Cold', label: 'Cold' },
                    { value: 'Warm', label: 'Warm' },
                    { value: 'Hot', label: 'Hot' },
                ]
            },
            gender:{
                mode: 'single',
                value: ['Male'],
                options: [
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' }
                ]
            }
        }
    }
}
</script>
